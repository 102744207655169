export default function Custom404() {
  return (
    <div style={{display: 'flex', justifyContent: 'center', alignContent: 'center',  height: 'inherit', alignItems: 'center', color: '#000'}}>
      <section style={{textAlign: 'center', color: 'white'}}>
        <h2>Siden ikke funnet...<br/>Beklager, det ser ut som du har kommet til en bomvei.</h2><br/>
        <div style={{fontWeight: 700, fontSize: '18px', marginTop: '32px'}}>
          Men bruk søket for å finne det du leter etter!
        </div>
      </section>
    </div>
  );
}